.thingEditor__content {
  padding: 1rem;
}

.thingEditor__actionBarWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0rem;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  /* justify-content: space-between; */
  /* justify-content: space-between; */
  /* align-content: flex-start; */
}
.thingEditor__actionBarWrapper.right {
  justify-content: flex-end;
}

.thingEditor__actionBarSpacer {
  width: 2rem;
}

.thingEditor__textarea {
  font-family: "Nunito", sans-serif;
  border-style: none;
  resize: none;
  background-color: var(--texteditBG);
  /* padding: 0.5rem; */
  margin-top: 0.2rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  width: calc(100% - 2rem);
  /* padding: 0rem 0rem 0rem 1rem; */

  padding: 1rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.thingEditor__textarea.name {
  font-size: 1.5rem;
  padding-bottom: 0.2rem;

  /* height: 2.5rem; */
  /* line-height: 2.5rem; */
  resize: none;
  /* background-color: rgb(221, 242, 250); */
  font-weight: 800;
  margin-bottom: 1rem;
  /* border: rgb(63, 180, 219) solid 2px; */
  background-color: var(--transparent);
  border-style: none none solid none;
  border-radius: 0px !important;
  border-width: 1px;
  border-color: var(--black);
}

.thingEditor__textarea.description {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: var(--texteditBG);
}

.thingEditor__textarea.show {
  user-select: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
}

.thingEditor__cardinfo {
  position: absolute;
  left: 2rem;
}

.thingEditor__dropzone {
  padding: 0.3rem;
  border-radius: 0.3rem;
  width: 100%;

  height: min(5rem, max-content);
  margin-bottom: 20px;
  background-color: lightblue;

  border: solid 1px lightblue;
  color: grey;

  background-color: rgba(173, 216, 230, 0.164);
}

.thingEditor__dropzone.active {
  background-color: rgb(63, 180, 219);
}
.thingEditor__thumbnailsWrapper {
  width: 100%;
  padding: 0.3rem;
  height: 0px;
  opacity: 0;

  overflow: auto;
  white-space: nowrap;

  background-color: var(--lightGreyBG);
  border-radius: 0.3rem;

  width: 100%;
  z-index: 2;
  user-select: none;

  /* background-color: aliceblue; */
}
.thingEditor__thumbnailsWrapper.populatetd {
  opacity: 1;
  height: min-content;
  background-color: rgba(255, 255, 255, 0);
}

.thingEditor__thumbnail {
  display: inline-block;
  height: 5rem;
  /* padding: 14px; */

  position: relative;
  margin: 0.2rem;
}

.thingEditor__thumbnail_remove {
  position: absolute;
  right: -0.5rem;
  bottom: -0.2rem;
  /* right: 0px;
  top: 0px; */
  color: var(--white);
  transition: all 0.2s ease;
}
.thingEditor__thumbnail_remove:hover {
  color: var(--danger);
  /* transform: scale(1.2); */
}

.thingEditor__thumbnail_img {
  height: 100%;
  opacity: 1;
  box-shadow: var(--baseShadow);
}

.thingEditor__dropInfo {
  background-color: var(--primaryOpaque);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  transition: opacity 0.2s ease;
  text-align: center;
}
.thingEditor__dropInfo.active {
  opacity: 1;
}

.testwidth {
  background-color: lightblue;
}

.thingEditor__processing {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  opacity: 0;
  background-color: var(--transparent);
  /* opacity: 1;
  background-color: var(--positiveOpaque); */

  color: var(--black);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  user-select: none;
  pointer-events: none;
  font-size: 5rem;
  font-weight: bold;
  transition: all 1s ease;
}

.thingEditor__processing.inProgress {
  opacity: 1;
  background-color: var(--white);
}
.thingEditor__processing.inProgress > * {
  color: var(--black);
  stroke: var(--black);
}

.thingEditor__processing.done {
  opacity: 1;
  background-color: var(--white);
  pointer-events: all;
}

.thingEditor__processing.done > * {
  opacity: 1;
  /* background-color: var(--white); */
  pointer-events: all;
  color: var(--transparent);
  stroke: var(--positive);
}

.thingEditor__uploadProgressState {
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: all 0.5s ease;
}
.thingEditor__uploadProgressState.done {
  opacity: 1;
  pointer-events: all;
  user-select: none;
}

.thingEditor__processingMessage {
  text-align: center;
  font-size: 2rem;
  opacity: 0;
}
.thingEditor__processingMessage.show {
  text-align: center;
  font-size: 2rem;
  opacity: 1;
}

.thingEditor__loader {
  position: relative;
  /* background-color: orange; */
  width: 11rem;
  height: 11rem;
  overflow: hidden;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.thingEditor__circularLoader {
  color: red;
  animation: rotate 2s linear infinite;
}

.thingEditor__circularLoaderPath {
  /* stroke-dasharray: 10%, 90%; */
  stroke-dashoffset: 0;
  /* animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite; */
  stroke-linecap: round;
  /* stroke: var(--black); */
  stroke-width: 0.15rem;
  transition: all 0.2 ease-in;
}

.thingEditor__loaderIcon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 8rem;
  padding: 1.5rem;
  /* border: solid red 1.5rem; */
  opacity: 0;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  position: absolute;
  width: 8rem;
  height: 8rem;
  left: 0px;
  right: 0px;
  color: var(--positive);
  /* background-color: rgba(255, 0, 0, 0.363); */
}
.thingEditor__loaderIcon.show {
  opacity: 1;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: var(--white);
  }
  40% {
    stroke: var(--white);
  }
  66% {
    stroke: var(--white);
  }
  80%,
  90% {
    stroke: var(--white);
  }
}
@keyframes color {
  0% {
    stroke: var(--white);
  }
  40% {
    stroke: var(--white);
  }
  66% {
    stroke: var(--white);
  }
  80%,
  90% {
    stroke: var(--white);
  }
}

.thingEditor__pdfFiles {
  margin-bottom: 1rem;
}

.thingEditor__pdfListItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row;
  padding: 0.2rem;
}
.thingEditor__pdfListItem.pad {
  padding: 0.2rem 1rem 0.2rem 1rem;
}
.thingEditor__pdfListItem:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.thingEditor__pdfListItem .pdfName {
  position: relative;
  padding-left: 0.1rem;
  /* padding-bottom: 0.3rem; */
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipText {
  visibility: hidden;
  max-width: 30rem;
  background-color: var(--white);
  box-shadow: var(--baseShadow);
  color: var(--black);
  text-align: left;
  border-radius: 6px;
  padding: 0.2rem;

  /* Position the tooltip */
  position: absolute;
  top: -1rem;
  left: 1rem;
  z-index: 10;
}
.thingEditor__pdfIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
}
.thingEditor__pdfIcon:hover .tooltipText {
  visibility: visible;
}
.thingEditor__pdfRemove {
  padding-left: auto;
}

.thingEditor__controlls {
  background-color: var(--texteditBG);
  padding: 1rem;
  border-top: var(--lightGrey) solid 1px;
}
