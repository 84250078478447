
/* Colors generated via color2css plugin for sketch */
/* Document: /Users/jochenleinberger/Documents/awailio/awailioDesign/awailio.sketch */
/* Created: Fri Jan 01 2021 20:14:20 GMT+0100 (CET) /} */
:root {
--lightGreyBG: #e9e9e9ff;
--white: #ffffffff;
--updateBG: #fdfdd6ff;
--primary: #7e20b2ff;
--primaryLight: #bf00ffff;
--primaryOpaque: #8c00b8b4;
--danger: #e40000ff;
--dangerLight: #ffc1c1ff;
--warning: #ffe7e7ff;
--inactiveText: #afafafff;
--inactiveBody: #ebebebff;
--grey: #727272ff;
--greyOpaque: #7272729a;
--black: #171717ff;
--blackOpaque: #171717f2;
--whiteBG: #f5f5f5ff;
--darkGrey: #555555ff;
--lightGrey: #d4d4d4ff;
--texteditBG: #f2f2f2ff;
--transparent: #ffffff00;
--positive: #31b931ff;
--positiveOpaque: #31b931b6;
--highlight: #00a6ffff;
--highlightOpaque: #4492dece;
--pdfColor: #e3574cff;
}