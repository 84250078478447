:root {
  /* --lightGreyBG: rgb(233, 233, 233);
  --white: rgb(255, 255, 255);
  --whiteBG: rgb(255, 255, 255);
  --updateBG: rgb(253, 253, 214);
  --primary: lightseagreen;
  --primaryLight: rgb(144, 185, 183);
  --primaryOpaque: rgba(32, 178, 171, 0.815);
  --danger: rgb(228, 0, 0);
  --warning: rgba(255, 72, 0, 1);
  --dangerLight: rgb(255, 193, 193);
  --inactiveText: #afafaf;
  --inactiveBody: #ebebeb;
  --black: rgb(65, 65, 65);
  --grey: rgb(114, 114, 114); */
  --cardRadius: 0.3rem;
  /* --baseShadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2); */
  --baseShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --elevatedShadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  --hoverShadow: 0 0 6px 1px rgba(168, 168, 168, 0.8);
  --contrastShadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  --titleBarHeight: 4rem;
  --thingCardWidth: 20rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.app__ContentArea {
  margin-top: var(--titleBarHeight);
  /* background-color: red; */
  /* background-color: var(--lightGreyBG); */
}

.app__contentFrame {
  margin: 5rem 1rem 0rem 1rem;
}

.dangerZone {
  /* color: var(--danger); */
  /* background-color: var(--dangerLight); */
  border: solid 1px var(--danger);
  padding: 1rem;
}

/* Debug items */

.debugDiv {
  position: fixed;
  width: 100%;
  padding: 20px;
  bottom: 0px;
  color: white;
  background-color: rgba(53, 3, 153, 0.808);
  font-family: monospace;
}

.debugButton {
  background-color: rgb(245, 218, 245);
  width: fit-content;
  padding: 10px;
  margin: 2px;
}

.debugButton:hover {
  background-color: magenta;
}

/* thingCards */
.thingCard__wrapper {
  position: relative;
  width: var(--thingCardWidth);
  height: fit-content;
  padding: 0rem;
  margin: 1rem;

  background-color: var(--white);
  /* box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2); */
  border-radius: var(--cardRadius);
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: center; */
  overflow: hidden;
  padding-bottom: 1rem;
}
.thingCard__nonImageWrapper {
  padding: 0rem;
  display: flex;
  flex-flow: column;
}

.thingCard__nonImageWrapper.upload {
  background-color: green;
}
.thingCard__wrapper.edit {
  box-shadow: var(--baseShadow);
}
.thingCard__wrapper.edit:hover {
  box-shadow: var(--elevatedShadow);
}
.thingCard__wrapper.static {
  box-shadow: var(--baseShadow);
  /* box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1); */
}
.thingCard__wrapper.static.modified {
  animation-name: thingCard__modified;
  animation-duration: 1s;
}

/* @keyframes thingCard__modified {
  0% {
    background-color: var(--white);
  }
  20% {
    background-color: var(--updateBG);
  }
  100% {
    background-color: var(--white);
  }
} */

.material-icon {
  /* <i className="material-icons">face</i> */
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  text-align: center;
  /* width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  height: 2rem; */
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icon.grey {
  color: var(--grey);
}
.material-icon.black {
  color: var(--black);
}
.material-icon.lightGrey {
  color: var(--lightGrey);
}

.material-icon.onerem {
  font-size: 1rem;
  line-height: 0rem;
}
.material-icon.shadow {
  text-shadow: rgb(0, 0, 0) 0px 0px 5px;
  /* box-shadow:  */
}
.material-icon.shadowSharpe {
  text-shadow: rgb(0, 0, 0) 0px 0px 2px;
  /* box-shadow:  */
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: var(--primary) radial-gradient(circle, transparent 1%, var(--primary) 1%)
    center/15000%;
}
.ripple:active {
  background-color: rgba(255, 255, 255, 0.137);
  background-size: 100%;
  transition: background 0s;
}

.danger.ripple:hover {
  background: var(--dangerLight) radial-gradient(circle, transparent 1%, var(--dangerLight) 1%)
    center/15000%;
}

.appMessages {
  position: fixed;
  top: var(--titleBarHeight);
  z-index: 2;
  width: 90%;
  left: 5%;
  /* right: 0%; */
}

.messagesBlockWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dangerLight);
  padding: 1rem;
}

.clearMessagesWrapper {
  margin-top: 1rem;
}

.message {
  position: relative;
  margin: 3px;
  width: calc(100% - 10px - 1rem);
  top: 0px;
  left: 0px;
  /* transform: translate(0rem -0rem); */
  height: fit-content;
  border-radius: 0.2rem;
  /* padding: 0.5rem; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  border: solid 1px var(--white);
  color: var(--black);
  overflow: hidden;
  margin: 0.1rem;
  padding: 0.3rem;
  /* font-weight: 900; */
}

.message.error {
  background-color: var(--white);
  border: solid 1px var(--black);
  color: var(--black);
}

.uploadProgressInfo {
  z-index: 3;
  color: var(black);
}
.uploadProgress {
  /* width: 100%; */
  height: 100%;

  top: 0px;
  left: 0px;
  background-color: var(--positive);
  position: absolute;
  opacity: 1;
}
