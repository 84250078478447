.titleBar__main {
  position: fixed;
  top: 0px;
  background-color: var(--white);
  padding-left: 1rem;
  font-size: 1.2em;
  font-weight: 900;
  color: rgb(51, 51, 51);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: calc(100%);

  height: var(--titleBarHeight);
  z-index: 1;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
}

.titleBar__claim {
  padding: 1em;
}
.titleBar__grid {
  display: grid;
  grid-template-columns: 10em auto 10em;
}

.titleBar__searchInput {
  margin-left: 1em;
  background-color: rgba(255, 255, 255, 0.425);
  color: rgba(32, 178, 171, 0.521);
  padding: 1em;
  border-style: none;
  font-size: 1.2em;
  font-weight: 100;
  font-family: "Roboto";
}

@media print {
  .titlebar__wrapper {
    display: none;
  }
}

.titleBar__submenue {
  position: fixed;
  top: var(--titleBarHeight);
  transform: translatey(-15rem);
  right: 0rem;
  background-color: white;
  opacity: 0;
  z-index: 1;
  min-width: 10rem;
  transition: all 0.2s ease-out;
  padding: 5rem 0rem 0.2rem 0rem;
  box-shadow: var(--baseShadow);
  user-select: none;
  pointer-events: none;

  /* border-radius: var(--cardRadius); */
}
.titleBar__submenue.show {
  opacity: 1;
  transform: translatey(-4rem);
  user-select: none;
  pointer-events: all;
}

.titleBar__submenueList {
  list-style-type: none;
  left: 0px;
}
.titleBar__submenueList > .entry {
  margin-left: 0.5rem;
  padding: 0.5rem;
  /* 
  border-style: none none solid;
  border-width: 1px;
  border-color: white; */
}
.titleBar__submenueList > .entry:hover {
  font-weight: bold;
}

.titleBar__floatingMainMenue {
  z-index: 2;
  position: fixed;
  top: 0rem;
  right: 0rem;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0.2rem 0.5rem 0rem 0rem;
  font-size: 2rem;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

button.float {
  border-radius: 100%;
  color: var(--primary);
  /* background-color: var(--primary); */
  background-color: var(--white);
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
}

.titleBar__mainAddThing {
  opacity: 1;
  transition: all 0.4 ease;
}

.titleBar__mainAddThing.hide {
  opacity: 0;
}
