.thingCard__textarea {
  width: 100%;
  border-style: none;
}

.thingCard__uploadsWrapper {
  position: absolute;
  width: var(--thingCardWidth);
  top: 0px;
  left: 0px;
  display: flex;
  flex-flow: column;
}

.thingCard__uploadsProgress {
  height: 0.5rem;
  background-color: var(--primary);
  transition: opacity 0.1s ease;
}
.thingCard__uploadsProgress.hide {
  opacity: 0;
}

.thingCard__imageBox {
  position: relative;
  /* width: var(--thingCardWidth);
  height: fit-content; */
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thingCard__imageBox.big {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: var(--blackOpaque);
  z-index: 10;
}

.thingCard__imagesWrapper {
  position: relative;
  width: var(--thingCardWidth);
  height: var(--thingCardWidth);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.big .thingCard__imagesWrapper {
  width: 100vw;
  height: 100vh !important;
}

.thingCard__image {
  position: absolute;

  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 0;
  transition: transform 0.3s ease-out;
}
.big .thingCard__image {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: visible;
}
.thingCard__imagesWrapper:hover > .thingCard__image {
  transform: scale(1.01);
}
.thingCard__image.show {
  opacity: 1;
}

.thingCard__imagePaginationWrapper {
  position: absolute;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0rem;
  padding-bottom: 0.2rem;
}
/* .big .thingCard__imagePaginationWrapper {

} */

.thingCard__imagePagination {
  background-color: var(--white);
  width: 0.5rem;
  height: 0.5rem;
  margin: 0rem 0.2rem 1rem 0.2rem;
  border-radius: 100%;
  box-shadow: var(--contrastShadow);
}
.thingCard__imagePagination.select {
  background-color: var(--primaryLight);
}

.thingCard__expandImage {
  z-index: 2;
  bottom: 0rem;
  right: 0px;
  position: absolute;
  padding: 0.2rem;
}

/* .thingCard__expandImage_icon {
  text-shadow: #000 0px 0px 5px;
} */

.imageFrame {
  position: absolute;
  width: 100%;
  height: 100%;
  /* border: solid red 2px; */
}

.big .imageFrame {
  height: 70%;
}

.thingCard__edit {
  position: absolute;
  top: 0rem;
  right: 0rem;
  padding: 0.3rem;
}

.thingCard__bottomBar {
  height: fit-content;
  /* background-color: black; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.thingCard__name {
  font-size: 1.5rem;
  padding: 0rem 1rem 0rem 1rem;
  font-weight: 800;
  margin-top: 1rem;
}
.thingCard__description {
  position: relative;
  white-space: pre-wrap;
  font-size: 1rem;
  width: calc(100% - 2rem);
  padding: 0rem 1rem 0rem 1rem;
  overflow-y: scroll;
  height: min-content;
  max-height: 30rem;
  margin-bottom: 1rem;
}

.thingCard__pdfBox {
  /* margin-top: 1rem; */
  /* padding: 0rem 1rem 0rem 1rem; */
  margin-bottom: 1rem;
}

/* .thingCard__qrScannerButton { 

} */
