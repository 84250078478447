.auth__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 3rem;
  text-align: center;
  color: var(--black);
}

.auth__confirm_persist {
  text-align: center;
}
