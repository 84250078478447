/*  */
button {
  position: relative;
  /* display: block; */
  padding: 0.5rem;
  overflow: hidden;
  /* border-width: 0; */
  outline: none;
  border-radius: 0.25rem;
  color: white;
  user-select: none;
  border: solid 1px rgba(255, 255, 255, 0);
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: var(--grey);
  transition: background-color 0.2s, color 0.2s, border 0.2;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

button.round {
  border-radius: 100%;
}

button:hover {
  box-shadow: var(--hoverShadow);
}

/* button > .material-icon {
  padding-right: 0.4rem;
} */

button.primary {
  background-color: var(--primary);
}

button.signup {
  background-color: rgba(255, 255, 255, 0);
}

button.inactive {
  background-color: var(--inactiveBody);
  color: var(--inactiveText);
  border-color: rgba(255, 255, 255, 0);
  user-select: none;
  pointer-events: none;
}

button.transparent {
  background-color: var(--transparent);
  color: var(--transparent);
  border-color: var(--transparent);
  user-select: none;
  pointer-events: none;
}

button.darkOutline {
  background-color: var(--white);
  border: solid 1px var(--black);
  color: var(--black);
}
button.plain {
  background-color: var(--transparent);
  border: solid 1px var(--transparent);
  color: var(--black);
}

button.plain:hover {
  color: var(--primary);
}
button.plain:hover * {
  box-shadow: none;
  color: var(--primary);
}

button.plainDanger {
  background-color: var(--transparent);
  border: solid 1px var(--transparent);
  color: var(--white);
  box-shadow: none;
}

button.plainDanger:hover * {
  box-shadow: none;
  color: var(--danger);
}

button.danger.solid {
  background-color: var(--white);
  color: var(--danger);
  border: solid 1px var(--danger);
}
button.danger {
  background-color: var(--danger);
  color: white;
  border: solid 1px var(--danger);
}

button.danger.inactive {
  background-color: white;
  color: var(--inactiveBody);
  border: solid 1px var(--inactiveBody);
}

button.overlay {
  background-color: var(--transparent);
  color: var(--white);
  /* border: solid 1px var(--primary); */
  justify-content: center;
  align-items: center;
  width: min-content;
  height: min-content;
}

button.overlay:hover * {
  box-shadow: none;
  color: var(--primary);
}
button.overlay:hover {
  box-shadow: none;
  /* box-shadow: var(--baseShadow); */
  background-color: var(--white);
}
/* .btn.inactive {
  pointer-events: none;
  background-color: #ebebeb;
  color: #afafaf;
  user-select: none;
} */

/* button.primary.info {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0);
  border-style: none;
  color: #afafaf;
  user-select: none;
} */
